import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, List, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import MainBody from './MainBody'
import SortIcon from '@mui/icons-material/ArrowDownward'
import AddIcon from '@mui/icons-material/Add';

import { getDocs, collection, deleteDoc, doc, query, where } from "firebase/firestore";
import { db } from '../firebase-config';

import { ArtworkDataRow } from "../interfaces";
import ArtworkArtistListItem from '../components/ArtworkArtistListItem';
import CreateArtwork from './CreateArtwork';
import EditArtwork from '../components/EditArtwork';

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const deleteArtworkHandler = async (artworkId: string, cb: Function) => {
  const artworkDoc = doc(db, "art", artworkId);
  await deleteDoc(artworkDoc);
  // window.location.reload();
  cb();
};

// const editArtworkHandler = async (data: ArtworkDataRow, cb: Function) => {
//   const artworkDoc = doc(db, "art", data.id);
//   setDoc(artworkDoc, data).then(() => {
//     // window.location.reload();
//     cb();
//   });
// };

const columns: TableColumn<any>[] = [
  {
    name: "Artwork",
    selector: r => r.title,
    sortable: true
  },
  {
    name: "Published",
    selector: r => r.published === true ? "True" : "False",
  },
  {
    name: "Artists", 
    selector: r => r.artists.length,
  },
  {
    name: "SFW",
    selector: r => !(r.nsfw === true) ? "True" : "False"
  },
  {
    name: "Preview",
    selector: r => r.img,
    format: r => {
      if (r.customRender && r.customRender === "mp4"){
        return(<>
          <video autoPlay loop muted style={{ height: "50px", width: "50px", objectFit: "cover" }}>
            <source src={r.img} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        </>)
      }
      return (<img
        src={r.img}
        alt={"Preview"}
        // height={"100px"}
        style={{ height: "50px", width: "50px", objectFit: "scale-down"}}
      />)
    }
  }
];

interface CustomExpanderComponentProps extends ExpanderComponentProps<ArtworkDataRow> {
  // currently, props that extend ExpanderComponentProps must be set to optional.
  isAuth?: boolean;
  runUpdate?: any; // doesn't like being Function
  showNSFW?: boolean;
}

const ExpandedComponent: React.FC<CustomExpanderComponentProps> = ({ data, isAuth, runUpdate, showNSFW }) => {
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  // const [features, setFeatures] = useState<Array<CharacterItem>>(data.features || []);
  // const [artists, setArtists] = useState<Array<any>>(data.artists);
  // const [title, setTitle] = useState<string>(data.title);
  // const [img, setImg] = useState<string>(data.img);
  // const [published, setPublished] = useState<boolean>(data.published || false);
  // const [nsfw, setNsfw] = useState<boolean>(data.nsfw || false);

  const handleClickEditOpen = () => {
    setEditOpen(true);
  };

  // const handleCloseEdit = () => {
  //   setEditOpen(false);
  // };

  // const handleCloseEditAndSave = (data: ArtworkDataRow) => {
  //   editArtworkHandler(data, runUpdate);
  //   handleCloseEdit();
  // }

  const handleClickRemoveOpen = () => {
    setRemoveConfirmOpen(true);
  };

  const handleCloseRemove = () => {
    setRemoveConfirmOpen(false);
  };

  const handleCloseRemovePerformRemoval = (artworkId: string) => {
    deleteArtworkHandler(artworkId, runUpdate);
    handleCloseRemove();
  };
  
  // return <pre>{JSON.stringify(data, null, 2)}</pre>;
  return (
    <div>
      <Container>
        <Box>
          {
            data.customRender && data.customRender === "mp4"
            ?
              <>
                <video autoPlay loop muted style={{ height: "250px" }}>
                  <source src={data.img} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
              </>
            :
              <img src={data.img} alt={`${data.title} (preview)`} style={{ height: "250px" }} />
          }
          {(data.artists && data.artists.length > 0) ? (
            <div>
              <Typography variant="body1" gutterBottom>Artist(s)</Typography>
              <Paper>
                <List>
                  {data.artists.map((i: any, idx) => {
                    return (
                      <ArtworkArtistListItem key={`artist-${idx}`} idx={idx} artistDocRef={i} />
                    )
                  })}
                </List>
              </Paper>
            </div>
          ) : (<div><Typography variant="body1" gutterBottom>Looks like artist data is missing somehow! You shouldn't see this message.</Typography></div>)}
        </Box>
        {isAuth && <div>
          <Typography variant="body1">Options for artwork</Typography>
          <Box>
            <Button onClick={handleClickEditOpen}>Edit</Button>
            <Button onClick={handleClickRemoveOpen}>Remove</Button>
          </Box>
        </div>}
      </Container>
      <Dialog
        open={removeConfirmOpen}
        onClose={handleCloseRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm artwork deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to remove the artwork from the list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemove}>Cancel</Button>
          <Button onClick={() => {handleCloseRemovePerformRemoval(data.id)}} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} onClose={() => {setEditOpen(false)}}>
        <div style={{ padding: "1em" }}>
          <EditArtwork data={data} isAuth={isAuth || false} onEditCallback={() => {setEditOpen(false); runUpdate()}} showNSFW={showNSFW || false} />
        </div>
      </Dialog>
    </div>
  )
};

function ManageArtworks({ isAuth, showNSFW }: {isAuth: boolean, showNSFW: boolean}) {
  const [artworks, setArtworks] = useState<Array<any>>([]);
  const artworkCollectionRef = collection(db, "art");
  const [pending, setPending] = useState<boolean>(true);
  const [addOpen, setAddOpen] = useState<boolean>(false);

  const runUpdate = () => {
    const getArtworks = async () => {
      if(showNSFW){
        const data = await getDocs(artworkCollectionRef);
        setArtworks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      } else { // filter out docs where onlyShowIfNsfw is true
        const q = query(artworkCollectionRef, where("nsfw", "==", false));
        const querySnapshot = await getDocs(q);
        setArtworks(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      }
    };
    getArtworks();
    setPending(false);
  };

  useEffect(() => {
    runUpdate();
  }, [deleteArtworkHandler, showNSFW]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainBody title={"Manage Artworks"} showNSFW={showNSFW}>
      <Container>
        <DataTable
          title="Manage Artworks"
          columns={columns}
          data={artworks}
          sortIcon={<SortIcon />}
          pagination
          selectableRows={false}
          expandOnRowClicked
          // expandableRows={isAuth}
          expandableRows={true}
          expandableRowsHideExpander
          expandableRowsComponent={ExpandedComponent}
          expandableRowsComponentProps={{ isAuth: isAuth, runUpdate: runUpdate, showNSFW: showNSFW }}
          progressPending={pending}
        />
      </Container>
      {
        isAuth && 
        // <Fab color="primary" aria-label="add" sx={{ ...fabStyle }} component={Link} to="/createartist">
        <Fab color="primary" aria-label="add" sx={{ ...fabStyle }} onClick={() => {setAddOpen(true)}}>
          <AddIcon />
        </Fab>
      }
      <Dialog open={addOpen} onClose={() => {setAddOpen(false)}}>
        <div style={{ padding: "1em" }}>
          <CreateArtwork isAuth={isAuth} onAddCallback={() => {setAddOpen(false); runUpdate()}} showNSFW={showNSFW} />
        </div>
      </Dialog>
    </MainBody>
  )
}

export default ManageArtworks